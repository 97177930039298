
@font-face{
	font-family:noto-sans;
	src:url(../font/NotoSansKR-Regular.woff2) format("woff2"),url(../font/NotoSansKR-Regular.woff) format("woff"),url(../font/NotoSansKR-Regular.otf) format("opentype");
	font-style:normal;
	font-weight:400
}
@font-face{
	font-family:noto-sans;
	src:url(../font/NotoSansKR-Light.otf) format("opentype"),url(../font/NotoSansKR-Light.woff2) format("woff2"),url(../font/NotoSansKR-Light.woff) format("woff");
	font-style:normal;
	font-weight:300
}
@font-face{
	font-family:noto-sans;
	src:url(../font/NotoSansKR-Medium.woff2) format("woff2"),url(../font/NotoSansKR-Medium.woff) format("woff"),url(../font/NotoSansKR-Medium.otf) format("opentype");
	font-style:normal;
	font-weight:500
}
@font-face{
	font-family:noto-sans;
	src:url(../font/NotoSansKR-Bold.woff2) format("woff2"),url(../font/NotoSansKR-Bold.woff) format("woff"),url(../font/NotoSansKR-Bold.otf) format("opentype");
	font-style:normal;
	font-weight:700
}

html { font-family: noto-sans; height: 100%; display: block; font-size: 10px; } /* 62.5% //scroll-behavior: smooth;*/
html, body { margin: 0; font-size: 10px; }
body { min-width: 192rem; }
main { display: block; min-width: 192rem; }

p, ul, ol, h1, h2, h3, h4, h5, figure { margin: 0; padding: 0; }
* { box-sizing: border-box;         }
ul { list-style: none; }
header { z-index: 2; transition: background-color 0.5s, height 0.3s; height: 10rem;position: fixed; top: 0; left: 0; right: 0; }
i { font-style: normal; display: inline-block; }
body.home { background-color: black; }

*, *.active, *.focus { outline: none; -webkit-tap-highlight-color: transparent; }

@media only screen and (min-device-width:320px)  {
	html{font-size:9px} /* 62.5% */
}
@media only screen and (min-device-width:360px)  {
	html{font-size:10px} /* 62.5% */
}
@media only screen and (min-device-width:375px)  {
	html{font-size:10.4167px} /* 65.104166% */
}
@media only screen and (min-device-width:384px)  {
	html{font-size:10.6667px} /* 66.666666% */
}
@media only screen and (min-device-width:412px)  {
	html{font-size:11.4444px} /* 71.5277777% */
}
@media only screen and (min-device-width:414px)  {
	html{font-size:11.5px} /* 71.875% */
}
@media only screen and (min-device-width:480px)  {
	html{font-size:13.3333px} /* 83.333333% */
}
@media only screen and (min-device-width:600px)  {
	html{font-size:16.6667px} /* 104.16666% */
}
@media only screen and (min-device-width:768px)  {
	html{font-size:10px} /* 62.5% */
}


:root {
	--main-color: #43EDFF;
}

.mt15 { margin-top: 1.5rem !important; }
.mt20 { margin-top: 2rem !important; }
.mt25 { margin-top: 2.5rem !important; }
.mt30 { margin-top: 3rem !important; }
.mt40 { margin-top: 4rem !important; }
.mt45 { margin-top: 4.5rem !important; }
.mt50 { margin-top: 5rem !important; }
.mt55 { margin-top: 5.5rem !important; }
.mt60 { margin-top: 6rem !important; }
.mt70 { margin-top: 7rem !important; }
.mt80 { margin-top: 8rem !important; }
.mt90 { margin-top: 9rem !important; }
.mt100 { margin-top: 10rem !important; }
.mt120 { margin-top: 12rem !important; }

.pb50 { padding-bottom: 5rem !important; }
.pb100 { padding-bottom: 10rem !important; }
.pb150 { padding-bottom: 15rem !important; }

.rltv { position: relative !important; }
.wbg { background-color: white; }
.lbg { background-color: #F7F7F7; }
.rcf { background-color: white; }
.srws { background-color: white; }
.bbg { background-color: #4C5768; }

.fixed { position: fixed !important; }
.static { position: static !important; }
.relative { position: relative !important; }
.absolute { position: absolute !important; }

.txt-center { text-align: center !important; }
.txt-right { text-align: right !important; }
.txt-left { text-align: left !important; }
.txt-justify { text-align: justify !important; }

.intro header, .service header, header.ong { background-color: rgba(34, 39, 43, 0.933); }
header.ong { height: 5rem; }
header .header-wrap { padding: 0 1rem; align-items: center; display: flex; justify-content: space-between; max-width: 144rem; height: 100%; margin: auto; }
#com-logo { width: 10rem; height: 4rem; background:url("../img/logo_fintech.svg") no-repeat center; background-size: cover; width: 10.8rem; height: 2.6rem; }
header nav { height: 100%; width: 60rem; }
section { min-width: 192rem; min-height: 50rem; overflow: hidden; }
section > .wrp, footer > .wrp { width: 144rem; margin: auto; height: 100%; position: relative; }
.wrp.w192 { width: 192rem }

section.wss { display: flex; align-items: center; justify-content: center; height: 50.7rem; background-color: black; position: relative; z-index: 1; }
.slg { text-align: center; color: white; font-size: 7rem; font-weight: 300; position: relative; }
.slg span { display: block; line-height: 9.7rem; }
.slg:after { content: ""; display: block; margin: 3rem auto 0; background-color: var(--main-color); height: 0.5rem; width: 11.1rem; }

section.idl .wrp, section.alc .wrp, section.sin .wrp { display: flex; align-items: flex-end; }
section .img-hld { flex: 0 0 auto; width: 50%; max-width: 94.8rem; height: 90rem; position: relative; }
section .img-hld .img { width: 100%; height: 100%; opacity: 0.59; background-repeat: no-repeat; background-position: left top;}
section.idl .img-hld .img { background-image: url("../img/img_idl.jpg"); }
section.alc .img-hld .img { background-image: url("../img/img_alc.jpg"); }
section.sin .img-hld .img { background-image: url("../img/img_sin.jpg"); }
.cap { position: absolute; top: 10rem; right: 5.4rem; color: white; font-size: 8rem; font-weight: 300; line-height: 7.2rem; text-align: right; letter-spacing: -0.28rem; }
.cap:after { width: 0.4rem; height: 13.6rem; background-color: var(--main-color); position: absolute; top: 50%; right: -1.8rem; display: block; content: ""; margin-top: -6.4rem; }
.cap strong { font-size: 7rem; font-weight: 600; letter-spacing: -0.32rem; }

.wtf { color: white; line-height: 3.8rem; font-size: 2rem; font-weight: 300; opacity: 0; transition: opacity 0.5s; width: 96rem; position: fixed; top: 50%; left: 96rem; padding: 0 4rem 0 2rem !important; }
.wtf.show { opacity: 1;  }
h2 { font-size: 6rem; font-weight: 500; line-height: 8rem; letter-spacing: -0.144rem; }
h3 { font-size: 4rem; line-height: 5.5rem; font-weight: 300; }
h3 strong { font-weight: 600; }
.wtf h3 + p { margin-top: 4.1rem; }
.img-hld + .wtf { margin: 0; padding: 0 0 20rem 2.4rem; }

.cvu { position: absolute; top: 0; left: 0; right: 0; bottom: 0; background-image: linear-gradient(black 10%, transparent 30%); }
.cvu.reverse { background-image: linear-gradient(transparent 80%, black 90%); }

h4 { font-size: 3rem; font-weight: 600; color: #333333; }
.djr { margin-top: 1.6rem; font-size: 2.8rem; line-height: 4rem; color: #666666; }
h4 + p.djr { margin-top: 1.6rem; }
.djr + .bdu { margin-top: 1.6rem; }
.djr.rwt { width: 46rem; }

.home > section:nth-child(3) { height: 90rem; }
.home > section:nth-child(4) { height: 90rem; }
.home > section:nth-child(5) { height: 90rem; }

.syy { height: 110.7rem; text-align: center; position: relative; background-color: black; }
.syy .wrp { padding-top: 15.8rem; }
.syy p { color: white; font-size: 3rem; line-height: 5rem; letter-spacing: -0.15rem; font-weight: 300; margin-top: 5rem; }

.syy .cls { margin-top: 15rem; }
.cls { display: flex; justify-content: space-between; }
.cls li { flex-shrink: 0; /* cursor: pointer; */ display: flex; align-items: center; justify-content: center; font-size: 2.5rem; font-weight: 300; line-height: 4rem; letter-spacing: -0.075rem; border-radius: 50%; color: var(--main-color); border-width: 0.2rem; border-style:  solid; border-color: var(--main-color); width: 19.5rem; height: 19.5rem; position: relative; box-sizing: border-box; }
.cls li:before { transition: filter 0.3s, border 0.3s; content: ""; width: 20.2rem; height: 20.2rem; display: block; border: 1rem solid var(--main-color); border-radius: 50%; position: absolute; top: -0.6rem; left: -0.6rem; box-sizing: border-box; -webkit-filter: blur(0.5rem); filter: blur(0.5rem); }
/* .cls li:hover:before { filter: blur(1.5rem); border-width: 2rem; } */

ul.nav-list { font-size: 2rem; height: 100%; margin: 0; padding: 0; list-style: none; display: flex; align-items: center; justify-content: space-between;}
ul.nav-list li { color: white; height: 100%; display: flex; align-items: center; }
ul.nav-list li a:hover { color: var(--main-color);  }
ul.nav-list li div { transition: top 0.3s, height 0.4s; font-size: 2rem; padding: 0 2rem; color: white; width: 100vw; position: absolute; top: 10rem; left: 0; height: 0; background-color: rgba(34, 39, 43, 0.7); display: flex; overflow: hidden; }
.ong ul.nav-list li div { top: 5rem; }
ul.nav-list li div ul { width: 144rem; margin: auto; display: flex; align-items: center; justify-content: center; }
ul.nav-list li:last-child ul { justify-content: flex-end; }
ul.nav-list li ul li { cursor: pointer; }
ul.nav-list li ul li:hover { color: var(--main-color); }
ul.nav-list li ul li + li { margin-left: 2rem; }
ul.nav-list li:hover div { height: 8rem; }

a, a:link, a:hover, a:active, a:visited { color: inherit; text-decoration: none; }

.main-video-section { height: 106.9rem; position: relative; z-index: 1; }
.main-video-section > div { width: 192rem; }
.main-video-section .e-slg { position: absolute; top: 64.9rem; left: 24rem; font-size: 5rem; color: white; font-weight: 300;}
.main-video-section .pt-br { position: absolute; top: 72.3rem; left: 24rem; background-color: var(--main-color); height: 0.5rem; width: 11.1rem; }
.main-video-section .k-slg { color: white; position: absolute; top: 73.6rem; left: 24rem; font-weight: 300; font-size: 8rem; }
.main-video { overflow: hidden; width: 100%; height: 100%; object-position: center; position: absolute; top: 0; left: 0; object-fit: cover; filter: blur(3px); -webkit-filter: blur(3px); -moz-filter: blur(3px); -o-filter: blur(3px); -ms-filter: blur(3px);}

footer { background-color: #30363B; height: 28.6rem; min-width: 192rem; position: relative; z-index: 2; }
footer .wrp { display: flex; justify-content: space-between; padding: 7rem 21.2rem 7rem 12.3rem; position: relative; align-items: flex-end; }
footer .logo { background: url("../img/logo_fintech_bh.svg") no-repeat center; background-size: cover; width: 10.8rem; height: 2.6rem; opacity: 0.5; }
footer .logo + .acp { margin-top: 2.28rem; }
.links { display: flex; justify-content: flex-start; align-items: center; }
.links .ics { width: 6rem; height: 6rem; background-repeat: no-repeat; background-position: center; background-size: cover; margin: 0 1.7rem 1.4rem 1.7rem; }
.links .ics.api { background-image: url("../img/ico_apple..svg"); }
.links .ics.ggi { background-image: url("../img/ico_google.svg"); }
.links .ics.yti { background-image: url("../img/ico_youtube.svg"); }
.cpr, .acp { font-size: 1.4rem; line-height: 2.5rem; letter-spacing: -0.0336rem; color: #999999; }
.cpr { font-weight: 300; }

.mr50 { margin-right: 5rem; }

.h-auto { height: auto !important; }

.flx { display: flex; }
.flx-center { display: flex; align-items: center; justify-content: center;}
.flx-end { justify-content: flex-end; }

.tpi { height: 40rem; margin-top: 10rem; background-position: center top; background-size: cover; min-height: 40rem; }
.tpi.bg_01 { background-image: url("../img/bg_01.jpg"); }
.tpi.bg_02 { background-image: url("../img/bg_02.jpg"); }
.tpi.bg_03 { background-image: url("../img/bg_03.jpg"); }
.tpi.bg_04 { background-image: url("../img/bg_04.jpg"); }


	/* 약관페이지 */
	.terms .tpi{ height: auto;}
.msg { color: white; text-align: center; }
.msg .tit { font-size: 4rem; font-weight: 600; letter-spacing: -0.2rem; line-height: 5.6rem; }
.msg .mit { font-size: 2rem; letter-spacing: -0.1rem; line-height: 2.8rem; margin-top: 3rem; }

.s_tit { margin: 13rem auto 0; font-size: 4rem; font-weight: 600; color: #333333; line-height: 5.4rem; letter-spacing: -0.2rem; text-align: center; }
.s_tit span { position: relative; }
.s_tit span:after { left: 0; right: 0; content: ""; display: block; position: absolute; width: 100%; max-width: 11.1rem; height: 0.5rem; background-color: #56B5FF; margin: auto; bottom: -1.4rem; }
.s_tit .sbt { font-size: 3rem; line-height: 4.2rem; color: #666666; font-weight: 400; }
.s_tit span + .sbt { margin-top: 3rem; }

.vbs { height: 77rem; background-color: #E9EDEF; }

.elw { display: flex; align-items: center; justify-content: space-between; }
.s_tit + .elw { margin-top: 9rem; }
.elw > .elm + .elm { margin-left: 2.4rem; }
.elw > .elm { width: 46.4rem; /* height: 23.5rem; */ border-radius: 2.2rem; overflow: hidden; box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.16); }
.elm > ul { background-color: #52C6D3; padding: 2rem 0; text-align: center; font-size: 3rem; color: white; }
.elm > div { background-color: white; padding: 2.5rem 0 2rem; font-size: 2.5rem; color: #666666; line-height: 3.4rem; text-align: center; }
.elm > ul li:first-child { font-weight: 600; line-height: 4rem; }
.elm > ul li:nth-child(2) { line-height: 4.1rem; }
.elw > .elm:nth-child(2) ul { background-color: #43A0E5; }
.elw > .elm:nth-child(3) ul { background-color: #3C69B8; }

.mdb { flex-shrink: 0; background: url("../img/img_my-data-bunsin.png") no-repeat; width: 70.8rem; height: 58rem; }
.mba { flex-shrink: 0; background: url("../img/img_mba.png") no-repeat; width: 70.8rem; height: 58rem; }

.bdu { font-size: 2.5rem; line-height: 3.5rem; color: #666666; }
.bdu li { background: url("../img/icon-blue-dots.svg") no-repeat left center; padding-left: 1.6rem; }
.bdu li + li { margin-top: 1.4rem; }

.dti { font-size: 3rem; font-weight: 600; color: #333333; }

.bbj { margin-left: 2.4rem; }

.mtpt { margin-top: -1px !important; padding-top: 1px !important; }

[data-href] { cursor: pointer; }

.ceo-int { padding-bottom: 8rem; }
.ceo-int figure { overflow: hidden; position: relative; color: white; background:url("../img/img_ceo_intro.png") no-repeat center; background-size: cover; height: 24.4rem; }
.ceo-int .ttx { position: absolute; white-space: nowrap; bottom: 2rem; left: 50.2rem; letter-spacing: -0.25rem; font-size: 5rem; line-height: 7.2rem; }
.ceo-int article { font-size: 2rem; color: #333333; text-align: right; line-height: 4rem; }
.ceo-int article p + p { margin-top: 3rem; }
.ceo-int .ggmn { text-align: right; font-size: 2.5rem; font-weight: 500; color: #333333; }

.com-his { background-color: #E9EDEF; overflow: hidden; }
.com-his .wrp.w192 { overflow: hidden; padding-bottom: 14rem; }

.his-el + .his-arw { margin-top: 1rem; }
.his-arw { -webkit-user-select: none; user-select: none; display: flex; padding-left: 7rem; }
.his-arw > div { transition: filter 0.3s; filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.2)); flex: 0 0 auto; width: 4rem; height: 5rem; background-size: cover; background-repeat: no-repeat; background-position: center; cursor: pointer;  }
.his-arw > div:hover { filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.4)); }
.his-arw > div:active { transition: none; filter: drop-shadow(1px 1px 10px rgba(0, 0, 0, 0.3)); position: relative; top: 2px; left: 2px; }
.his-arw > div:first-child { background-image: url("../img/hisPrevArw.png"); }
.his-arw > div:nth-child(2) { margin-left: 4rem; background-image: url("../img/hisNextArw.png"); }

.his-el { display: inline-flex; padding: 0 0rem 3rem 5rem; margin: 10rem 0 0 0; overflow-x: auto; width: 100%; position: relative; }
.his-el .ele { position: relative; padding-top: 12.3rem; }
.his-el .ele:first-child { margin-left: 2rem; }
.his-el .ele:last-child,
.his-el .ele:last-child .year { padding-right: 5rem; }
.his-el .ele + .ele { margin-left: 2.4rem;}
.his-el .ele .box { position: relative; padding: 7.2rem 2.3rem 4rem; border-radius: 2.8rem; background-color: rgba(255, 255, 255, 0.62); box-shadow: 0 0.3rem 2rem rgba(0, 0, 0, 0.16); width: 34.3rem; height: 53.2rem;}
.his-el .ele .box ul { height: 100%; overflow-y: auto; font-size: 2.5rem; line-height: 4rem; color: #333333;  }
.his-el .ele .box li { position: relative; padding-left: 1.3rem; }
.his-el .ele .box li + li { margin-top: 2.4rem; }
.his-el .ele .box li:before { content: ""; display: block; position: absolute; top: 1.5rem; left: 0; width: 0.5rem; height: 0.5rem; background-color: #333333; border-radius: 50%; }
.his-el .year { text-align: center; width: 100%; letter-spacing: -0.75rem; position: absolute; top: 0; left: 0; font-size: 15rem; font-weight: 600; color: #2A3657; opacity: 0.3; line-height: 20.4rem; }
.his-el .ele.now .year { opacity: 0.66; }

.com-his .wrp.w192:before { content: ""; display:block; position: absolute; top: 10rem; left: 0; height: 68.5rem; width: 8rem; background-image: linear-gradient(90deg, rgba(233, 237, 239, 1), rgba(233, 237, 239, 0)); z-index: 1; pointer-events: none;}
.com-his .wrp.w192:after { content: ""; display:block; position: absolute; top: 10rem; right: 0; height: 68.5rem; width: 8rem; background-image: linear-gradient(-90deg, rgba(233, 237, 239, 1), rgba(233, 237, 239, 0)); pointer-events: none;}

.map .root_daum_roughmap { margin: 10rem auto 0; z-index: 0; width: 1440px !important; }
.map .root_daum_roughmap .wrap_map { height: 760px !important; }
.root_daum_roughmap .wrap_btn_zoom { top: 1rem !important; right: 1rem !important; bottom: auto !important; }

.lci { display: flex; margin: 6rem auto 15rem !important; justify-content: space-between; }
.lci ul { font-size: 2.5rem; color: #333333; font-weight: 500; line-height: 4rem; }
.lci ul li { white-space: nowrap; }
.lci ul li:last-child { margin-top: 3rem; }
.lci ul li.adrs { padding-left: 3.7rem; background: url("../img/icon_anchor.svg") no-repeat left center; background-size: 3rem 3rem; }
.lci ul li.email { padding-left: 3.7rem; background: url("../img/icon_email.svg") no-repeat left center; background-size: 3rem 3rem; }
.lci ul li.phone { padding-left: 3.7rem; background: url("../img/icon_phone.svg") no-repeat left center; background-size: 3rem 3rem; }

.moni-dw { position: relative; height: 90rem; background: url("../img/img_bg_01.svg") no-repeat right -44rem bottom -13rem #F4F8FB; background-size: 139.7rem 145.6rem; }
.moni-dw .mvt { position: absolute; top: 20.7rem; left: 12rem; }

.moni-dw .phn { top: 14.8rem; left: 102.3rem; width: 26.6rem; height: 55.7rem; background: url("../img/img_njb_phone.png") no-repeat center; background-size: cover; position: absolute; }

.stores { display: flex; }
.stores li { cursor: pointer; background-repeat: no-repeat; width: 12.8rem; height: 4rem; background-size: cover; }
.stores li + li { margin-left: 1rem; }
li.app_store { background-image: url("../img/img_app_store.svg"); }
li.google_play { background-image: url("../img/img_google_play.svg"); width: 13.5rem; }
li.moni_web { background-image: url("../img/img_moni_web.svg"); }

.njb { font-size: 4.8rem; letter-spacing: -0.1152rem; line-height: 7.1rem; }
.njb .emp { font-weight: 600; }
.njb li { color: black;}
.njb .desc { font-size: 2.4rem; letter-spacing: -0.0576rem; line-height: 3.6rem; }
.njb .desc em { color: #4B82DC; font-style: normal; }


.mnys { display: flex; flex-wrap: wrap; }
.mnys li { flex-basis: 46%; height: 9rem; background-repeat: no-repeat; background-position: left center; padding-left: 11rem; background-size: 9rem; margin-bottom: 4.7rem; }
.mnys li:nth-child(even) { margin-left: 6.2rem; }
.mnys li > div:first-child { font-size: 3rem; font-weight: 600; color: #333333; }
.mnys li > div:nth-child(2) { font-size: 2rem; color: #666666; }
.mnys li.credit { background-image: url("../img/icon_ys_credit.svg"); }
.mnys li.carrier { background-image: url("../img/icon_ys_carrier.svg"); }
.mnys li.health { background-image: url("../img/icon_ys_health.svg"); }
.mnys li.town { background-image: url("../img/icon_ys_town.svg"); }
.mnys li.car { background-image: url("../img/icon_ys_car.svg"); }
.mnys li.family { background-image: url("../img/icon_ys_family.svg"); }
.mnys li.travel { background-image: url("../img/icon_ys_travel.svg"); }
.mnys li.choice { background-image: url("../img/icon_ys_choice.svg"); }

.rwp { padding: 11.5rem 8.2rem 15rem 8.2rem; }
.rdp { width: 55rem; height: 55rem; border-radius: 50%; box-shadow: 0 0 4rem rgba(0, 0, 0, 0.5) inset; background: url("../img/img_phone_001.png") no-repeat center bottom -8rem #1DC6C6; background-size: 27.84rem 58.33rem; }
.rwp .rpi { position: absolute; top: 11.5rem; left: 73.2rem; }

.rpi .tit { font-size: 4rem; font-weight: 600; color: #333333; }
.rpi .sub_tit { font-size: 3rem; color: #666666; }
.rpi p { font-size: 2rem; color: #666666; line-height: 3rem; text-align: justify; }
.ifg { height: 1rem; }

.ngns { height: 110rem; min-height: 110rem; background-color: white;}
.ngns i.arrow { background-repeat: no-repeat; background-size: cover; background-position: center; cursor: pointer; position: absolute; top: 50%; transform: translateY(-50%); width: 9.051rem; height: 9.051rem; transition: opacity 0.3s; }
.ngns i.arrow.prev { background-image: url("../img/icon_arrow_prev.svg"); left: 18.475rem; left: 10%; }
.ngns i.arrow.next { background-image: url("../img/icon_arrow_next.svg"); right: 18.475rem; right: 10%; }
.f_slider { display: flex; width: 100%; height: 100%; overflow-x: auto; }
.f_slider li { width: 100%; flex-shrink: 0; background-size: 192rem 110rem; background-repeat: no-repeat; background-position: center; scroll-snap-align: start;}
.f_slider li.sli_01 { background-image: url("../img/img_moni_01.png"); }
.f_slider li.sli_02 { background-image: url("../img/img_moni_02.png"); }
.f_slider li.sli_03 { background-image: url("../img/img_moni_03.png"); }
.f_slider li.sli_04 { background-image: url("../img/img_moni_04.png"); }
.f_slider li.sli_05 { background-image: url("../img/img_moni_05.png"); }

.ngns .prev.hide,
.ngns .next.hide { opacity: 0; pointer-events: none; }

.slider_dots { display: flex; }
.slider_dots li { transition: width 0.3s, background-color 0.3s; width: 2rem; height: 2rem; background-color: rgba(119, 119, 119, 0.5); border-radius: 1rem; flex: 0 0 auto; cursor: pointer; }
.slider_dots li.on { background-color: white; cursor: default; width: 5rem; }
.slider_dots li + li { margin-left: 2.5rem; }
.ngns .slider_dots { position: absolute; bottom: 11rem; right: 32.1rem; }


.xMandatory { scroll-snap-type: x mandatory; }

.cats { padding-bottom: 13rem; }
.cat { cursor:pointer; transition: max-height 0.4s; width: 95rem; margin: auto; border-radius: 2.5rem; box-shadow: 0 0.2rem 1rem rgba(0, 0, 0, 0.16); background-image: linear-gradient(90deg, #0BB1D9 9.6rem, white 9.6rem); min-height: 10rem; max-height: 10rem; padding: 0 5.2rem 4.2rem 14.1rem; overflow: hidden; }
.cats .cat { position: relative; }
.cats .cat:before { content: ""; display: block; width: 2rem; height: 0.4rem; background-color: white; position: absolute; top: 4.9rem; left: 4.1rem; }
.cats .cat:after { transition: transform 0.4s; content: ""; display: block; width: 0.4rem; height: 2rem; background-color: white; position: absolute; top: 4.1rem; left: 4.9rem; }
.cats .cat.on:after { transform: rotate(90deg); }
.cat.on { max-height: 50rem; }
.cat + .cat { margin-top: 3.8rem; }
.cat .tit { transition: color 0.4s; font-size: 2.5rem; font-weight: 500; color: #333333; line-height: 10rem; }
.cat.on .tit { color: #0BB1D9; }
.cat p { font-size: 2rem; color: #666666; line-height: 3rem; text-align: justify; word-break: break-all; }

.rcf { padding: 13.1rem 0 0; }
.rcf p { color: #333333; font-size: 4rem; line-height: 7.2rem; }
.rcf p em { font-style: normal; font-weight: 500; }
.rcf p.sub_con { color: #666666; font-size: 3rem; line-height: 5rem; margin-top: 5rem; }

.icon_rcf_01 { background: url("../img/icon_rcf_01.svg") no-repeat center; background-size: cover; width: 14.4rem; height: 14.4rem; }
.icon_rcf_02 { background: url("../img/icon_rcf_02.svg") no-repeat center; background-size: cover; width: 14.4rem; height: 14.4rem; }

.rcf_desc { display: flex; margin: 13rem 0 14.3rem; }
.rcf_desc > div { flex: 1 1 50%; position: relative; padding-left: 19.4rem; }
.rcf_desc .icon_rcf_01, .rcf_desc .icon_rcf_02 { position: absolute; top: 0; left:0; }
.rcf_desc .ul { font-size: 2.5rem; line-height: 4rem; color: #666666; margin-top: 2.15rem; }
.ul > li { background: url("../img/icon_bull.svg") no-repeat left top 1.3rem; background-size: 0.9rem 1.5rem; padding-left: 2rem; }
.rcf_desc .tit, .cya .tit { color: #56B5FF; font-size: 3rem; font-weight: 600; }

.bbg .s_tit, .bbg .sbt { color: white; }
.cya { background-color: #F7F7F7; }
.cya .cyi { margin-top: 15rem; }
.cyi { display: flex; flex-wrap: wrap; list-style-type: none; }
.cyi > li { flex: 1 1 50%; padding: 5.6rem 5rem 0 0; margin-bottom: 17.5rem; background-repeat: no-repeat; background-position: left top; }
.cyi .note { background-image: url("../img/icon_note.svg"); }
.cyi .pen { background-image: url("../img/icon_pen.svg"); }
.cyi .console { background-image: url("../img/icon_console.svg"); }
.cyi .gear { background-image: url("../img/icon_gear.svg"); }
.cyi p { font-size: 2rem; line-height: 5rem; color: #333333; }
.cyi ul { margin-top: 3.4rem; font-size: 2rem; line-height: 3rem; color: #333333; }
.cyi ul li { position: relative; padding-left: 1.2rem; }
.cyi ul li:before { content: ""; position: absolute; top: 50%; left: 0; width: 0.5rem; height: 2px; background-color: #333333; }

.vdl { display: flex; flex-wrap: wrap; justify-content: normal;}
.vdl li { flex-basis: 30%; margin-bottom: 5rem; width: calc(46.4rem);margin-right: 1.5rem;}
.vdl figure { overflow: hidden; width: 46.4rem; height: 27.3rem; background-color: #F5F5F5;  }
.vdl figure:hover img { transform: scale(1.1); }
.vdl figure img { width: 100%; height: 100%; object-fit: cover; object-position: center top; transform-origin: center; transition: transform 0.4s; }
.vdl .tit { height: 5.2rem; overflow: hidden; margin-top: 0.9rem; color: #000000; font-size: 2.2rem; font-weight: 500; line-height: 1.2; }
.vdl p { font-size: 1.7rem; line-height: 1.6; color: #666666; margin-top: 0.9rem; height: 5rem; overflow: hidden;}

.gry-btn { background-color: #999999; border: none; color: white; font-size: 2rem; height: 4.1rem; padding: 0 1.4rem; }
button { cursor: pointer; }
button.arw { background-image: url("../img/icon_arrow.svg"); background-repeat: no-repeat; background-position: right 1.4rem center; padding-right: 3.2rem; }
button.detail {margin-left: 5px;}
button.arw.back { background-image: url("../img/icon_arrow_back.svg"); background-position: left 1.4rem center; padding-left: 3.2rem; padding-right: 1.4rem; }

button.btn-set {background-color: white; color:#000000; border:none; font-size: 22px;}
.btn-set:hover {color:#0BB1D9}
.btn-set:focus {color:#0BB1D9}
.btn-mod {background-color: #0BB1D9;margin-right: 5px;}
.btn-del {background-color: red;}
.sjb {padding:5px; border: none;}

.scr { display: flex; justify-content: space-between; }
.scr > div { flex-basis: 49%; }
.scr > div > div, .scr iframe { width: 100%; height: 100%; background-color: white; }
.scr > div > p { font-size: 3rem; font-weight: 600; color: white; margin-top: 3rem; }

.icon_menu { width: 3.4rem; height: 3.4rem; background: url("../img/icon_menu.svg") no-repeat center; background-size: cover; }
header .icon_menu { display: none; }
header .icon_menu.on { background-image: url("../img/icon_menu_on.svg"); }

.label {margin-bottom: 96px;}
.label * {display: inline-block;vertical-align: top;}
.label .left {background: url("https://t1.daumcdn.net/localimg/localimages/07/2011/map/storeview/tip_l.png") no-repeat;display: inline-block;height: 24px;overflow: hidden;vertical-align: top;width: 7px;}
.label .center {background: url(https://t1.daumcdn.net/localimg/localimages/07/2011/map/storeview/tip_bg.png) repeat-x;display: inline-block;height: 24px;font-size: 12px;line-height: 24px;}
.label .right {background: url("https://t1.daumcdn.net/localimg/localimages/07/2011/map/storeview/tip_r.png") -1px 0  no-repeat;display: inline-block;height: 24px;overflow: hidden;width: 6px;}

.mydb { margin-top: 12rem; display: flex; }
.stug { margin-top: 10rem; display: flex; justify-content: flex-end; }

.srws .wrp { padding-bottom: 10rem; }
.srws .wrp .wtd {margin-top: 50px; margin-bottom: 30px;}
.srws .wrp .wtv {margin-top: 50px; margin-bottom: 15px;}
.srws .wrp .wtv > span {color: #000000; font-size: 17px; width: 100px; margin-right: 50px; text-align: center;}

.wtd .wts {color: #000000; font-size: 17px; width: 40px; margin-right: 97px; text-align: center;}
.wtd .wtss {color: #000000; font-size: 15px; width: 40px;}
.wtd .wtst {color: #000000; font-size: 17px; width: 40px; margin-right: 97px; text-align: center;}
.wtd .wtsr {color: #000000; font-size: 17px; width: 40px; margin-right: 50px; text-align: center;}

.wrp .wrt-btn {margin-top: 20px;}
.wrp .wrt-btn > button {width: 90px; height: 30px;}

.mvs > .wrp { padding-bottom: 15rem; }
.ytb { text-align: right; margin-top: 16rem; }
.y-badge { width: 70.8rem; height: 14rem; cursor: pointer; background-image: url("../img/img_yeodoman.png"); background-size: contain;border-radius: 12px;}

.w-detail { padding: 13rem 17rem 0; }
.w-detail .gry-btn + h2 { margin-top: 2.2rem; }
.dcr { text-align: right; font-size: 2.6rem; line-height: 3.6rem; letter-spacing: -0.0624rem; color:#000000;}
.w-detail h2 { font-size: 3rem; line-height: 1.5; color:#000000;}
.w-detail .dcr { font-size: 2rem; }
.w-detail h2 + .dcr { margin-top: 1rem; }
.w-detail * + figure { margin-top: 4rem; }
.w-detail figcaption { text-align: center; font-size: 1.7rem; color: #9e9e9e; }
.w-detail figure img { text-align:center; margin: auto; display: block; width: auto; height: auto; object-fit: cover; object-position: center; }
.w-detail img + figcaption { margin-top: 1.5rem; }
.w-detail figure + * { margin-top: 4rem; }
.w-detail .dcr + figure { margin-top: 4rem; }
.w-detail article { font-size: 1.8rem; line-height: 3rem; letter-spacing: -0.048rem; text-align: justify; word-break: break-all; color:#000000;}
.w-detail figure + article { margin-top: 6rem; }
.w-detail article p + p { margin-top: 2.5rem; }
.w-detail article + .txt-right .gry-btn { margin-top: 8rem; }

a.d-link { white-space: nowrap; max-width: 100%; overflow: hidden; text-overflow: ellipsis; color: white; font-size: 1.5rem; cursor: pointer; border: 1px solid #c1c1c1; background-color: #d8d8d8; padding: 0.2rem 1rem; margin-top: 0.7rem; display: inline-block; }
.d-link + .txt-right { margin-top: 3rem; }

.nscr { -ms-overflow-style: none; overflow: -moz-scrollbars-none; scrollbar-width: none; }
.nscr::-webkit-scrollbar { display: none; }

@media (max-width: 1024px) {
	html, body, main, footer, section { min-width: initial; }
	.wrp.w192 { width: auto; }
	header nav { width: 48.6rem; }
	ul.nav-list { font-size: 1.8rem; }
	header .header-wrap { width: auto; padding: 0 3.5rem; }
	section > .wrp { width: auto; }
	.main-video-section { height: 70.4rem; padding: 0; }
	.main-video-section .e-slg { top: 34.3rem; left: 3.5rem; font-size: 3rem;; }
	.main-video-section .pt-br { top: 39rem; left: 3.5rem; }
	.main-video-section .k-slg { top: 39.8rem; left: 3.5rem; font-size: 4.6rem; }

	section.wss { height: 25.8rem; min-height: initial; }
	.slg { font-size: 4.5rem; }
	.slg span { line-height: 6.1rem; }
	.slg:after { margin-top: 2.2rem; }

	section.idl .wrp, section.alc .wrp, section.sin .wrp { /* display: block; */ flex-direction: column; align-items: stretch; }
	section .img-hld { width: 100%; height: 53rem; max-width: initial; }
	section .img-hld .img { background-size: cover; background-position: center; }
	.cap { font-size: 6.4rem; font-weight: 300; top: 4rem; right: 4.5rem; }
	.cap strong { font-size: 5.6rem; font-weight: 600; letter-spacing: -0.22rem; }

	.idl .wtf, .sin .wtf { order: 2; }

	.wtf { position: static; opacity: 1; margin: 3.5rem 3.5rem 14rem !important; padding: 0 !important; width: auto; }
	.wtf h3 { font-size: 3.5rem; }
	.wtf p { font-size: 2.5rem; line-height: 3.8rem; font-weight: 300; }

	footer .wrp { width: auto; padding: 7rem 3.5rem 0; align-items: flex-start; }
	footer .links { justify-content: space-between; }
	footer .links .ics { margin: 0 0 1.4rem; }

	.syy { height: auto; padding-bottom: 13rem; }
	.syy p { font-size: 2.5rem; }

	.syy .cls { margin-top: 12rem; }
	.cls { flex-wrap: wrap; padding: 0 3.5rem; }
	.cls li { width: 25vw; height: 25vw; margin: 0 0 5vw; }
	.cls li:before { width: 26vw; height: 26vw; }

	.tpi { height: 25rem; min-height: auto; }
	.msg .tit { font-size: 3rem; line-height: 4.1rem; }
	.msg .mit { margin-top: 0.6rem; }

	.elw { padding: 0 3.5rem; align-items: stretch;}
	.elw .elm { flex-basis: 32%; }
	.s_tit { font-size: 3.5rem; margin-top: 10rem; }
	.s_tit .sbt { font-size: 2.5rem; }
	.s_tit + .elw { margin-top: 6.9rem }
	.elm > ul { font-size: 2.5rem; }
	.elm > div { font-size: 1.8rem; }
	.vbs { height: auto; padding-bottom: 10rem; }

	.mydb { display: block; margin: 6.2rem 3.5rem 0; }
	.mdb { width: auto; background-size: cover; height: 50rem; }
	.bbj { margin: 4rem 0 0; }
	.djr { font-size: 2.5rem; }
	.bdu { font-size: 2.4rem; }

	.stug { flex-direction:column; margin: 5rem 3.5rem; }
	.stug > div:first-child { order: 1; }
	.mba { width: auto; background-size: cover; height: 50rem; background-position: center; }
	.djr.rwt { width: auto; }
	.bdu.mr50 { margin-right: 0 !important; }

	.ceo-int figure { margin: 8.2rem 2.0rem 0; height: auto;  padding: 11rem 2rem 3rem; }
	.ceo-int .ttx { position: static; font-size: 4rem; white-space: normal; line-height: 4.8rem; }
	.ceo-int article { text-align: left; margin: 6rem 2rem !important; }
	.ceo-int .ggmn { margin: 5rem 2rem  0 0 !important; }

	.his-el { margin: 7rem 0 0 0; padding: 0 4rem 3rem; }
	.his-el .year { line-height: 16.2rem; font-size: 11.9rem; width: 100%; text-align: center; }
	.his-el .ele { padding-top: 9.8rem; }
	.his-el .ele .box { width: 27.4rem; height: 42.5rem; padding: 5.8rem 1.8rem 2.2rem; }
	.his-el .ele .box ul { font-size: 2rem; line-height: 3rem; }

	.map .root_daum_roughmap { margin: 7rem 3.5rem 0; width: auto !important; }
	.map .root_daum_roughmap .wrap_map { height: 400px !important; }

	.lci { width: auto !important;  margin: 6rem 3.5rem !important; display: block; }
	.lci ul + ul { margin-top: 4.4rem; }
	.lci ul li:last-child { margin-top: 1rem; }

	.moni-dw { height: 50rem; min-height: initial; background-size: 65.4rem 68.2rem; background-position: right -14rem top -19rem; }
	.moni-dw .mvt { left: 10vw; top: 7.2rem; }
	.moni-dw .phn { top: 7.5rem; right: 16vw; left: auto; width: 16.3rem; height: 34.1rem; }

	.njb { font-size: 3.5rem; line-height: 4.5rem; }
	.njb .desc { font-size: 2rem; line-height: 2.9rem; }

	.mnys { margin: 4.9rem 3.5rem 0 !important; }
	.mnys li { background-size: 8rem; flex-basis: 45%; height: auto; background-position: left top; }
	.mnys li > div:first-child { font-siZE: 2.5rem; }
	.mnys li > div:nth-child(2) { line-height: 3rem; }
	.mnw.pb150 { padding-bottom: 10rem !important; }

	.rdp { margin: auto; }
	.rwp { padding: 10rem 3.5rem 12.4rem 4rem; }
	.rwp .rpi { position: static; margin-top: 12.6rem; }

	.rpi .tit { font-size: 3.5rem; }
	.rpi .sub_tit { font-size: 2.5rem; padding-left: 3.5rem; }
	.rpi p { margin-top: 2.5rem; }

	.ngns { height: calc(100vw * 0.5625); min-height: auto; }
	.ngns i.arrow.prev, .ngns i.arrow.next { display: none; }
	.f_slider li { background-size: cover; }

	.cat { width: auto; margin-left: 3.5rem; margin-right: 3.5rem; }
	.cats .cat.mt100 { margin-top: 7.9rem !important; }
	.cat + .cat { margin-top: 3.5rem; }

	.rcf { padding: 6rem 3.5rem 0; }
	.rcf p { font-size: 3rem; line-height: 4rem; }
	.rcf p.sub_con { font-size: 2rem; line-height: 3rem; margin-top: 7.6rem; }
	.icon_rcf_01, .icon_rcf_02 { width: 9.8rem; height: 9.8rem; }
	.rcf_desc { margin: 6rem 0 13rem; }
	.rcf_desc > div { padding-left: 12rem; }
	.rcf_desc .tit, .cya .tit { font-size: 2.5rem; }
	.rcf_desc .ul { font-size: 2rem; margin-top: 1.8rem; line-height: normal; }
	.rcf_desc .ul > li { background-position-y: 0.5rem; }
	.rcf_desc .ul > li + li { margin-top: 1rem; }

	.cyi { padding: 0 3.5rem 4rem } 
	.cya .cyi { margin-top: 12.6rem; }
	.cyi p { line-height: 3rem; }
	.cyi ul { margin-top: 3rem; }
	.cyi > li { margin-bottom: 8.24rem; }

	.srws .wrp { padding-left: 3.5rem; padding-right: 3.5rem; }
	.vdl li { width: 48%; }
	.vdl .tit { font-size: 2rem; margin-top: 2.5rem; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; height: auto; }
	.vdl p { font-size: 1.6rem; margin-top: 1rem; }
	.gry-btn { font-size: 1.4rem; }
	.srws .wrp { padding-bottom: 5rem !important; }

	.mvs > .wrp { padding: 0 3.5rem 15rem; }
	.mvs .scr { display: block; }
	.scr > div > p { font-size: 2.6rem; margin-top: 3rem; }
	.scr > div + div { margin-top: 8.5rem; }

	.ytb { margin-top: 8.5rem; }
	.y-badge { width: 100%; height: auto; }

	.cyi > li.note, .cyi > li.gear { padding-top: 7rem; }

	.ngns .slider_dots { bottom: 3.17rem; left: 50%; right: auto; transform: translateX(-50%); }  
	.ngns .slider_dots li { width: 1.5rem; height: 1.5rem; border-radius: 7.25rem; }  
	.ngns .slider_dots li + li { margin-left: 2.2rem; }  
	.ngns .slider_dots li.on { width: 3rem; }  

	.vdl li { flex-basis: 48%; }
	.vdl figure { width: 100%; }

	.cvu { display: none; }

	.his-arw { padding-left: 6rem; }
	.his-arw > div { width: 4rem; height: 5rem; }

	.com-his .wrp.w192:before, .com-his .wrp.w192:after { display: none; }
}

@media (max-width: 768px) {
	header { height: 8rem; }
	header .header-wrap { padding: 0 2rem; }
	header nav { transition: max-height 0.3s, top 0.3s; max-height: 0; overflow: hidden; position: fixed; top: 8rem; left: 0; width: 100vw; text-align: center; background-color: rgba(0, 0, 0, 0.8); }
	header nav.on { max-height: 100vh; }
	header .icon_menu { display: block; /* transition: background-image 0.2s; */ }
	header ul.nav-list { display: block; font-size: 2.5rem; background-color: rgba(0, 0, 0, 0.7); height: auto; opacity: 0; transition: opacity 1s; }
	header .on ul.nav-list { opacity: 1; }
	header ul.nav-list li { height: auto; display: list-item; padding: 1rem 0;  }
	/* header ul.nav-list > li:last-child { padding-bottom: 0; } */

	header.ong .header-wrap nav { top: 5rem; }

	ul.nav-list li:hover div { height: auto; }

	ul.nav-list li div { position: static; height: auto; display: block; background-color: #22272B; margin-top: 1rem; }
	ul.nav-list li div ul { display: block; width: 100%; font-size: 1.6rem; }
	header ul.nav-list li ul li { margin-left: 0; }

	.cls li { font-size: 1.6rem; line-height: 2.3rem; width: 35vw; height: 35vw; margin-bottom: 10vw; }
	.cls li:before { width: 37vw; height: 37vw; }

	#com-logo { width:8.34rem; height: 2.02rem; }

	footer { height: auto; }
	footer .logo { width:9.577rem; height: 2.32rem; }
	footer .wrp { display: block; padding: 4.8rem 2rem 4.3rem; }
	footer .wrp > div:nth-child(2) { margin-top: 7.5rem; }
	footer .links, footer .cpr { padding: 0 2rem; }
	header .icon_menu { text-align: center; }
	
	.acp { line-height: 2.3rem; }

	.main-video-section .e-slg { top: 41.1rem; font-size: 2rem; }
	.main-video-section .pt-br { top: 44.6rem; width: 4rem; height: 0.2rem; }
	.main-video-section .k-slg { top: 45.4rem; font-size: 3rem; padding-right: 6rem; }

	.cap { font-size: 3rem; line-height: 2.7rem; letter-spacing: -0.12rem; top: 3.8rem; }
	.cap:after { height: 5.1rem; width: 0.2rem; margin-top: 0; transform: translateY(-50%); }
	.cap strong { font-size: 2.6rem; }

	section { min-height: initial; }
	section .img-hld { height: 34.2rem; }

	.slg { font-size: 3rem; }
	.slg:after { width: 5rem; margin-top: 1rem; }

	.wtf { margin: 2rem 2rem 4rem !important; padding: 0 !important; }
	.wtf h3 { font-size: 1.8rem; }
	.wtf p { font-size: 1.5rem; line-height: 3rem; }
	.wtf h3 + p { margin-top: 2rem; }

	.syy { padding-bottom: 9rem; }
	.syy .wrp { padding-top: 7rem; }
	.syy p { font-size: 1.3rem; line-height: 2.2rem; margin-top: 1.2rem; }
	.syy .cls { margin-top: 5.1rem; }

	.map .root_daum_roughmap { margin: 3rem 2rem 0; width: auto !important; }
	.map .root_daum_roughmap .wrap_map { height: 188px !important; }

	.tpi { margin-top: 8rem; min-height: auto; height: 14rem; }

	.msg .tit { font-size:1.6rem; line-height: 2.2rem; }
	.msg .mit { font-size: 1.2rem; margin-top: 0; }

	.s_tit { font-size: 2.3rem; margin-top: 5rem; }
	.s_tit .sbt { font-size: 1.6rem; }
	.s_tit span + .sbt { margin-top: 2rem; }
	.s_tit span:after { height: 0.2rem; width: 5rem; margin-left: -2.5rem; left: 50%; bottom: -1rem; }

	.vbs { padding-bottom: 6rem; }
	.elw { display: block; padding: 0 2rem; }
	.elw > .elm { width: auto; }
	.elw > .elm + .elm { margin-left: 0; margin-top: 2rem; }
	.s_tit + .elw { margin-top: 4rem; }
	.elm br { display: none; }
	.elm > ul { font-size: 1.6rem; padding: 2.4rem 0; }
	.elm > ul li { display: inline; }
	.elm > ul li:nth-child(2) { margin-left: 0.5rem; }
	.elm > div { font-size: 1.4rem; padding: 2.3rem 0; line-height: normal; }
	.elm > ul li { line-height: normal !important; }

	.mydb, .stug { margin: 4rem 0 0; }
	.mdb, .mba { height: 25rem; }
	.bbj { padding: 2rem; margin: 0; }

	h4 { font-siZE: 1.8rem; }
	.djr { font-size: 1.5rem; line-height: 2.5rem; }
	h4 + p.djr { margin-top: 1rem; }
	.bdu { font-size: 1.5rem; line-height: normal; }

	.ceo-int .mt70 { margin-top: 0 !important; }
	.ceo-int figure { height: 11rem; padding: 4.4rem 2rem 0; margin-top: 4rem !important; margin-left: 0; margin-right: 0; }
	.ceo-int figure .ttx { position: static; font-size: 2rem; white-space: normal; line-height: normal; letter-spacing: 0;}
	.ceo-int article { font-size: 1.5rem; padding: 2.5rem 2rem; line-height: 2.5rem; text-align: left; margin: 0 !important; }

	.ceo-int .ggmn { font-size: 1.5rem; padding: 0 2rem; margin-top: 3rem !important; text-align: left; line-height: 2.5rem; }

	.his-el { margin: 3rem 0 0; padding: 0 2em 3rem; }
	.his-el .ele { padding-top: 4rem; }
	.his-el .year { font-size: 5.8rem; line-height: 7.9rem; width: 100%; text-align: center; }
	.his-el .ele .box { width: 19rem; padding: 2.7rem 1.5rem; height: 24rem; border-radius: 1.5rem; }
	.his-el .ele .box ul { font-size: 1.4rem; line-height: 2rem; }
	.his-el .ele .box li { padding-left: 1rem; }
	.his-el .ele .box li + li { margin-top: 2rem; }
	.his-el .ele .box li:before { top: 0.7rem; }

	.lci { display: block; padding: 0 2rem;  margin: 1.2rem 0 4.5rem !important; }
	.lci ul { font-size: 1.4rem; line-height: 2.4rem; }
	.lci ul + ul { margin-top: 3rem; }
	.lci ul li { white-space: normal; }
	.lci ul li:first-child { line-height: 1.9rem; }
	.lci ul li:last-child { margin-top: 0.9rem; }
	.lci ul li.adrs,
	.lci ul li.phone,
	.lci ul li.email
	{ background-size: 1.1rem 1.6rem; padding-left: 1.5rem; background-position: 0; }

	.moni-dw { height: auto; background-position: left 20% bottom -2rem; background-size: 53.8rem 56.1rem;  }
	.moni-dw .wrp { padding: 5rem 0 7.34rem 2rem; }
	.moni-dw .mvt { position: static; }
	.njb { font-size: 2.7rem; line-height: 3.4rem;  }
	.njb .desc { font-size: 1.4rem; line-height: 2rem; }
	.moni-dw .njb + .stores { margin-top: 3.5rem !important; display: block; }
	.moni-dw .stores li { width: 14.6rem; height: 4.2rem; background-position: left center; background-size: cover; }
	.moni-dw .stores li + li { margin-left: 0; margin-top: 1.2rem; }
	.moni-dw .phn { position: static; margin: 10.6rem auto 0; }
	.mnys { display: block; }
	.mnys li { padding-left: 3rem; background-size: 2.4rem; margin-bottom: 2rem; }
	.mnys li:nth-child(even) { margin-left: 0; }
	.mnys li > div:first-child { font-size: 1.5rem; }
	.mnys li > div:nth-child(2) { font-size: 1.4rem; line-height: 2rem; }
	.mnw.pb150 { padding-bottom: 4rem !important; }
	.lbg .wrp { padding: 5.1rem 2rem 8rem; }
	.rdp { width: 32rem; height: 32rem; background-position: center top 1.5rem; background-size: 16.1rem 34rem; }
	.rwp .rpi { margin-top: 4rem; }
	.rpi .tit { font-size: 1.6rem; }
	.rpi .sub_tit { font-size: 1.4rem; padding-left: 0; }
	.rpi p { font-size: 1.3rem; line-height: 2rem; text-align: center; }
	.stores.mt30 { margin-top: 4rem !important; }
	.rpi .stores { justify-content: center; }
	.rpi .stores li + li { margin:  0 0 0 1rem; }

	.cats { padding-bottom: 6rem; }
	.cats .cat.mt100 { margin-top: 3rem !important; }
	.cat { background-image: linear-gradient(90deg, #0BB1D9 4.5rem, white 4.5rem); min-height: 4.7rem; max-height: 4.7rem; padding-left: 6.6rem; border-radius: 1rem; padding-right: 1rem; padding-bottom: 3rem; margin: 0 2rem; }
	.cat + .cat { margin-top: 1.5rem; }
	.cat .tit { font-size: 1.5rem; line-height: 4.7rem; }
	.cat p { font-size: 1.3rem; line-height: 2rem; }
	.cats .cat:before { width: 1rem; height: 0.2rem; top: 2.3rem; left: 1.9rem; }
	.cats .cat:after { width: 0.2rem; height: 1rem; top: 1.9rem; left: 2.3rem; }
	.rcf { padding: 4rem 2rem; }
	.rcf p { font-size: 1.5rem; line-height: 2rem; }
	.rcf p.sub_con { font-size: 1.4rem; margin-top: 2.2rem; line-height: 2.2rem; }

	.sub_con + .rcf_desc { margin-top: 3rem; }
	.rcf_desc { display: block; margin-bottom: 0; }
	.rcf_desc > div { padding-left: 8.5rem; }
	.rcf_desc .tit, .cya .tit { font-size: 1.5rem; }
	.rcf_desc .ul { font-size: 1.4rem; }
	.rcf_desc .tit + .ul { margin-top: 0.9rem; }
	.rcf_desc > div:nth-child(2) { margin-top: 4rem; }
	.icon_rcf_01, .icon_rcf_02 { width: 7rem; height: 7rem; }
	.rcf_desc .ul > li { background-position-y: 0; }

	.cya .cyi { margin-top: 5rem; display: block; }
	.rcf_desc .tit, .cya .tit { font-size: 1.6rem; }
	.cyi { padding-left: 2rem; padding-right: 2rem; }
	.cyi p { font-size: 1.4rem; line-height: 2rem; }
	.cyi .tit + p { margin-top: 1.5rem; }
	.cyi ul { font-size: 1.4rem; margin-top: 1rem; }
	.cyi > li { margin-bottom: 5.4rem; padding-right: 0; }
	.cyi > li:last-child { margin-bottom: 0; }

	.srws .wrp { padding: 0 2rem 5rem !important; }
	.vdl.mt70 { margin-top: 5.8rem !important; display: block; }
	.vdl figure { width: auto; height: 60vw; }
	.vdl .tit { font-size: 1.6rem; line-height: 2rem; margin-top: 0.6rem; }
	.vdl p { font-size: 1.4rem; line-height: 2rem; margin-top: 0.6rem; height: auto; }
	.vdl .txt-right.mt25 { margin-top: 0.6rem !important; }
	.gry-btn { height: 3rem; }
	.vdl li { margin-bottom: 3rem; width: 100%; }
	.vdl li:last-child { margin-bottom: 0rem; }

	.mvs .scr { margin-top: 3rem !important; }
	.mvs > .wrp { padding: 0 2rem 6.3rem; }
	.scr > div > div, .scr iframe { height: 100%; }
	.scr > div > p { font-size: 1.3rem; margin-top: 1.4rem; }
	.scr > div + div { margin-top: 2.3rem; }
	.ytb { margin-top: 3.3rem }

	.srws .w-detail { padding-top: 2rem !important; }

	h2 { font-size: 2.6rem; line-height: normal; }
	.dcr { font-size: 1.6rem; }
	.w-detail .dcr + figure { margin-top: 1rem; }
	.w-detail figure + article, .w-detail article + .txt-right .gry-btn { margin-top: 3rem; }
	.w-detail article { font-size: 1.6rem; }

	.ngns .slider_dots { bottom: 1rem; }  
	.ngns .slider_dots li { width: 0.6rem; height: 0.6rem; border-radius: 0.3rem; }  
	.ngns .slider_dots li.on { width: 1rem; }  
	.ngns .slider_dots li + li { margin-left: 0.7rem; } 
	.w-detail figure img { width: 100%; height: 100%; }

	.his-arw { display: none; }
	.com-his .wrp.w192 { padding-bottom: 3rem; }

}